import styled, { css } from 'styled-components';
import { AddCalenderButton } from '../MainEvents/styles';

const RegularFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Medium';

const CommentsContainer = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  font-family: ${RegularFont};
  
  > span {
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(0,0,0);
  }
`;

const CommentContent = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  margin-top: 15px;
  
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.isReply && css`
    padding: 0 0 0 0px;

    @media (max-width: 500px) {
      padding: 0 0 0 50px;
    }
  `}
`;

const ImageWrapper = styled.div`
  width: ${({comment}) => comment ? '32px' : '50px'};
  height: ${({comment}) => comment ? '32px' : '50px'};
  margin-right: 10px;
  float: left;
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    margin-right: 10px;
  }

  > img {
    width: 100%;
    height: 100%;
    float: left;
    border-radius: 50%;
  }
`;

const CommentSection = styled.div`
  display: inline-block;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: calc(100% - 42px);
  padding: 10px;
  white-space: pre-line;
  background: ${({ background }) => background ? background : '#FFF'};
  border-radius: 0px 6px 6px 6px;
  .profileName{
    font-family: ${BoldFont};
    font-size: 14px;
    color: #005c87;
    padding: 0px 0 10px;
    width: 100%;
  }
  > div: last-child {
    display: flex;
    justify-content: space-between;
  }
  span {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    text-align: justify;
    color: #005c87;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    pointer-events: none;
  }
  
  span:first-child {
    font-family: ${BoldFont};
    // text-transform: capitalize;
    span {
      letter-spacing: 0px;
      color: #3E3F42;
      font-family: Rubik-Bold;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      pointer-events: auto;
      cursor: pointer;
    }
  }
  
  > div {
    display: inline-block;
    color: #159fc9;
    margin-top: -3px;
    padding-bottom: 5px;
    
    @media (max-width: 500px) {
      margin-top: -5px;
    }
  }

  .CommentDescription {
    display: block;
    width: 100%;
    word-break: break-word;
    text-transform: none !important;
    text-align: justify;
    font-size: 16px;
    line-height: 18px;
    color: #005c87;
    font-family: Rubik-Regular !important;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  .image{
    display: block;
    width: 200px;
    height: auto;
    padding: 0 10px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  ${({ isReply }) => isReply && css`
    padding: 10px;
  `}
  
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    padding: 10px;
  }
`;

const StyledLink = styled.a`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  margin-top: ${({ marginTop }) => marginTop ? '0px' : '10px'};
  cursor: pointer;
  display: flex;
  gap:5px;
  align-items: center;
  line-height: 17px;
  color: #005c87;
  font-size: 14px;
  font-family: 'Rubik';
  text-decoration: none;
  width: ${({width}) => width ? '100%' : '200px'}
  justify-content: ${({justify}) => justify ? 'center' : ''}
  >div{
    width: auto
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #005c87;
    font-size: 14px;
    padding: 0 5px;
    font-family: 'Rubik-Medium';
  }

  > img {
    width: 18px;
    margin-left:42px;
  }
  
  ${({isReply}) => isReply && css`
    margin-left: 0;
  `}
  
  &:focus, &:hover {
    outline: none;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    margin-left: 50px;
    font-size: 12px;
  }
`;

const TimeContent = styled.span`
  text-align: right !important;
  margin-top: ${({ marginTop }) => marginTop ? '0px' : '10px !important'};
  display: flex !important;
  justify-content: flex-end !important;
  font-family: 'Rubik-Regular' !important;
  font-size: 14px !important;
  color: #005C8799 !important;
  line-height: 12px !important;
  width: ${({ width,fullWidth }) => width?"":fullWidth ? '75%' : 'calc(100% - 181px)'};
  margin:auto;
  margin-right:0px;
  ${({ isReply }) => isReply && css`
    width: 100%;
    margin-right: 5px;
  `}
  
  @media (max-width: 500px) {
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 82px)'};
    font-size: 10px;

    ${({ isReply }) => isReply && css`
      width: 100%;
    `}
  }
`;

const TimeContentV2 = styled.span`
  text-align: right !important;
  margin-top: ${({ marginTop }) => marginTop ? '0px' : '10px !important'};
  display: flex !important;
  justify-content: flex-end !important;
  font-family: 'Rubik-Regular' !important;
  font-size: 14px !important;
  color: #005C8799 !important;
  line-height: 12px !important;
  width: ${({ fullWidth }) => fullWidth ? '' : 'calc(100% - 181px)'};
  margin:auto;
  margin-right:0px;
  ${({ isReply }) => isReply && css`
    width: 100%;
    margin-right: 5px;
  `}
  
  @media (max-width: 500px) {
    width: ${({ mobfullWidth }) => mobfullWidth ? '100%' : 'calc(100% - 82px)'};
    font-size: 10px;

    ${({ isReply }) => isReply && css`
      width: 100%;
    `}
  }
`;

const ViewMoreLink = styled.a`
  font-size: 14px;
  color: #005c87;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-family: ${BoldFont}
  ${({ isReply }) => isReply && css`
    padding-left: 70px;
    
    @media (max-width: 500px) {
      padding-left: 0;
    }
  `}
  
  &:focus, &:hover {
    outline: none;
    color: #005c87;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const TextFieldContainer = styled.div`
  padding-bottom: 10px;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  background:${({ background }) => background ? 'white' : 'transparent'};
  @media (max-width: 500px) {
    padding: 0;
  }
`;

const SingleComment = styled.div`
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
`;

const ButtonContainer = styled.div`
  text-align: right;
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'}
`;

const PostCommentButton = styled(AddCalenderButton)`
  margin-top: 15px;
  float: none;
  height: 31px;
  width: 72px;
  padding: 0;
`;

const ReplyButton = styled(AddCalenderButton)`
  margin: ${({margin}) => margin ? '10px 0 0px 40px' : '15px 0 0px 80px'}
  float: left;
  cursor: pointer;
  text-transform: none;

  height: 40px;
  width: 107px;
  padding: 0;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border-radius: 6px;
font-family: 'Rubik-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
>img{
  margin-left:15px;
}
`;

const InputFieldContainer = styled.div`
  width: ${({ UpdateStatus, survey, width }) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  background: ${({ background }) => background ? 'transparent' : 'white'};
  -moz-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  -webkit-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  height: 'auto';
  border-color: ${({ comment, survey }) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb' : 'none'};
  border-image: ${({ comment }) => comment ? 'initial' : 'none'};
  padding: ${({ padding }) => padding ? padding : '15px'};
  border-radius: ${({ comment }) => comment ? '0px' : '6px'};
  border-width: ${({ comment, survey }) => comment || survey ? '1px' : '0px'};
  border-style: ${({ comment, survey }) => comment || survey ? 'solid' : 'none'};
  height: ${({ height }) => height};
  border: ${({ border }) => border};
  flex-direction: ${({ flex }) => flex && 'row-reverse'};
  ${({ height }) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    // border-radius: 6px 6px 0px 0px !important;
    border-radius: 20px !important;
  `}

  >div{
  // height: ${({ isCommentOrReply }) => isCommentOrReply && "100%"};

  }
  >div:nth-child(2){
    width: 90%; 
    .
  }
  @media (max-width: 500px) {
    width: ${({ UpdateStatus }) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    // width: calc(100% - 4px);
    width: 100%;
    color: #005c87;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #9c9c9c;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
    }  
  }
  
  .CustomTextAreaField2 {
    padding-top: 15px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #3A3A3A;
    resize: none;
    float: left;
    height: 80px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }
  }
`;

export { CommentsContainer, CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink,
  TextFieldContainer, SingleComment, ButtonContainer, PostCommentButton, ImageWrapper, TimeContentV2, ReplyButton, InputFieldContainer };
