/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import _, { isNull, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import {Img} from 'react-image'

import { CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink, CommentsContainer, TextFieldContainer, SingleComment,
  ImageWrapper } from './styles';
import { FieldContainer } from '../EventDetails/styles';
import { InputFieldContainer, RecognitionSection, RecognitionText} from '../SocialFeedsV2/styles';

import Image from '../Image';
import SocialFeedTextarea from '../SocialFeedsV2/SocialFeedTextarea'

import { convertDateInTimezone, convertUnicode, applyHighlights, getDecodedString } from '../../utils/methods';
import { getUserName, getUserProfileImage, getUserId, getUserPosts, likeUnlikePostComment, disableButton, likeUnlikeCommentReply } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

class PhotoComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyTextField: '',
      replyText: '',
      replyCount: 3,
      isUpdatedImage: false,
      mentionedUsers: [],
      // thankYou: [],
      // congrats: [],
      // jobWellDone: [],
      users: [],
      showGivenShoutout: false,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: 0,
      exemplifyID: null,
      ShowEmojiContainer: false,
      displayEmojiPicker: false,
      //isPostLiked: props.feed.own_like_post === 1,
      clicked: false,
      likeStatus: false,
      commentId: ''
    }
    this.myref = React.createRef();
    this.emojiref = React.createRef();
  }


  handleClick = (event) => {
    if ((this.state.replyTextField === '') && !isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && !isNull(this.myref.current) && !isUndefined(this.myref.current) && this.myref.current.contains(event.target)))
    {
      this.setState({ShowEmojiContainer: false});
    }
  };
  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      this.setState({displayEmojiPicker: false});
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    // this.replyClick && document.addEventListener("mousedown", this.replyClick);
    document.addEventListener("mousedown", this.handleEmojiClick);
    if(!this.props.userName) this.props.fetchUserName();
    if(!this.props.profileImage) this.props.fetchUserProfileImage();
    if(!this.props.userId) this.props.fetchUserId();
    this.checkBase64(this.props.profileImage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.replySuccess) {
      this.setState({
        replyText: '',
        replyTextField: ''
      });
    }
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
    document.removeEventListener("mousedown", this.handleEmojiClick);
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  checkBase64(profileImage) {
    if(profileImage) {
      let arr = profileImage.split('/');
      if(arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  onChangeValue = (e, native) => {
    let text =  e === '' ? native ? `${this.state.replyText} ${native || ''}` : '' : e.target.value;
    this.setState({
      replyText: text
    });
  };

  addReply = (e, commentId) => {
    e.preventDefault();
    const { users, userIdArray, replyText, taggedUser, mentionedUsers, exemplifyID } = this.state;
    const { postReply, id } = this.props;
    let shoutout = {
      exemplifies: mentionedUsers.length > 0 ? mentionedUsers : userIdArray
    }

    let reply =replyText, shoutOutUserName;
    if(!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray)) {
      shoutOutUserName = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    let image = "";
    postReply(commentId, reply, id, shoutout, shoutOutUserName, exemplify_id, image);

    this.setState({
      showTextAreaFor: null,
      reply: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      thankYou: [],
      congrats: [],
      jobWellDone: [],
      taggedUser: '',
      userIdArray: [],
      users: []
    });
  };

  showTextField = (id) => {
    const { replyTextField } = this.state;
    this.setState({
      replyTextField: replyTextField===id ? '' : id
    })
  };

  showMoreReplies = (replyCount) => {
    this.setState({
      replyCount
    });
  };

  showProfileImage = (comment, isOwn) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if(isOwn) {
      if(isUpdatedImage) {
        return (<img src={profileImage} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if(userId === comment.uid) {
      if(isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" />)
      }
      else {
        return (<Image image={profileImage} alt="avatar" />)
      }
    }
    else {
      return (<Image image={comment.profile_image} alt="avatar" />)
    }
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if(userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if(!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else 
        taggedUsers = taggedUserName;
    }
    this.setState({
      replyText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
    this.displayEmojiContainer();
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if(userIdArray.length !== 0) {
      if(userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else 
        return true;
    } else if(value && value.trim() !== ''){
      return false;
    }
    return true;
  };

  displayEmojiContainer = () => {
    this.setState((prev) => ({
      ShowEmojiContainer: !prev.ShowEmojiContainer
    }));
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmojiPicker: !prev.displayEmojiPicker
    }))
  }

  likeUnlikePost = (id, likestatus) => {
    const { likeUnlikePostComment, disableLikeButton, photo} = this.props;    
    const likeData = {
      comment_id: id,
      flag: likestatus === 1 ? 'unlike' : 'like',
    };
    this.setState({
      commentId: id
    });
    disableLikeButton();
    likeUnlikePostComment(likeData, photo.id);
  };
  likeUnlikePostCommentReply = (id, likestatus) => {
    const { likeUnlikeCommentReply, disableLikeButton, photo} = this.props;    
    const likeData = {
      reply_id: id,
      flag: likestatus === 1 ? 'unlike' : 'like',
    };
    this.setState({
      commentId: id
    });
    disableLikeButton();
    likeUnlikeCommentReply(likeData, photo.id);
  };

  renderTextArea = (value, id, name) => {
    const { noFloat, enableTrigger } = this.props;
    return(
      <TextFieldContainer noFloat={noFloat}>
        <CommentContent noFloat={noFloat}>
          <ImageWrapper comment={1}>
            {this.showProfileImage('', true)}
          </ImageWrapper>
          <InputFieldContainer comment name={name} height={this.state.showGivenShoutout ? '100px' : '50px'} padding={'0px'} flex={1}>
            <RecognitionSection float={'right'} width={'35%'} height={'48px'} display={'flex-end'} reply={1}>
              <div>
                <div onClick={() => this.displayEmojiContainer()}>
                  <Img src="/public/images/social/peer_recognition.svg" />
                </div>
                <div>
                  <Img src="/public/images/CommentReplyImages/camera.png" onClick={() => this.props.handlePhotoPostModal(2, this.props.id, id)}/>
                </div>
                {/* Emoji package */}
                {/* <div onClick={() => this.showEmojiPicker()}>
                  <img src="/public/images/CommentReplyImages/Emoji_2.png" />
                </div> */}
              </div>
              <button onClick={(e) => this.addReply(e, id)}>
                <Img src="/public/images/CommentReplyImages/addPost.png" />
              </button>
            </RecognitionSection>
            <SocialFeedTextarea
              userCompany={this.props.userCompany}
              addInspiration= {this.onChangeValue}
              inspirationQuote={value}
              updateText={this.updateText}
              users={this.state.users}
              userIdArray={this.state.userIdArray}
              placeholder={"Write your reply here..."}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
              ShowEmojiContainer={this.state.ShowEmojiContainer}
              myref= {this.myref}
              showEmojiPicker={this.showEmojiPicker}
              displayEmojiPicker={this.state.displayEmojiPicker}
              closeEmojiContainer={this.displayEmojiContainer}
              emojiRef={this.emojiref}
            />
          </InputFieldContainer>
        </CommentContent>
        {/* <ButtonContainer noFloat={noFloat}>
          <PostCommentButton color="#159fc9" onClick={(e) => this.addReply(e, id)} disabled={this.togglePostButton(value)}>post</PostCommentButton>
        </ButtonContainer> */}
      </TextFieldContainer>
    );
  };

  getTaggedUserId = (e) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(e.target.id, history);
    if(e.target.id){
      if(userId === e.target.id) {
        history.push('/profile');
      } else {
        history.push(`/profile/${e.target.id}`);
      }
    }
  };

  likePost = () => {
    this.setState((prev) => ({
      likeStatus: !prev.likeStatus 
    }))
  }

  render() {
    const { comments, fixHeight, noFloat, showThreeComments, t } = this.props;
    const { replyTextField, replyText, replyCount } = this.state;

    return (
      <CommentsContainer noFloat={noFloat}>
        <FieldContainer fixHeight={fixHeight} commentsSection noFloat={noFloat}>
          {
            showThreeComments ? comments && comments.slice(0,3).map((comment, index) => {
              const TimeOfComment = moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMMM DD');
              const chunkOfReply = (!_.isEmpty(comment.replies) ?comment && comment.replies && comment.replies.slice(0, replyCount) : []);
              return (
                <SingleComment key={index} noFloat={noFloat}>
                  <CommentContent noFloat={noFloat}>
                    <ImageWrapper comment={1}>
                      {this.showProfileImage(comment, false)}
                    </ImageWrapper>
                    <CommentSection noFloat={noFloat}>
                      <div className={"profileName"}>
                        {t(comment.fullname)}
                      </div>
                      {!this.props.isChallengeOrEvent ? <RecognitionText padding={'0px'}>
                        {/* <span>{comment.uid === userId ? 'You' : comment.fullname}&nbsp;</span> */}
                        {!_.isNull(comment.core_value) && <span className="first-span">
                          {t("Giving")}
                          <Img src={`${ImageUrl}/${comment.recognition_image}`} />
                          {comment.core_value} {t("to")}
                        </span>}
                        {!_.isNull(comment.shoutout_user_name) && <span className="second-span">
                          { !_.isNull(comment.shoutout_user_name) ?
                            // <span dangerouslySetInnerHTML={{__html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0]}}/> : null
                            <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0]))}</span> : null
                          }
                        </span>}
                        {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} /> */}
                        <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      </RecognitionText>:
                        // <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                        <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      }
                      {
                        comment.image ? <div className="image">
                          <Img src={`${ImageUrl}/${comment.image}`} />
                        </div>: null
                      }
                      {/* <div>.</div>
                      { !this.props.isChallengeOrEvent ?
                        <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                          { !_.isNull(comment.shoutout_user_name) ?
                            <span dangerouslySetInnerHTML={{__html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, ""))}}/> : null
                          }
                          { comment.recognition_image != null ? 
                            <ShoutoutImage comment>
                              <Image image={comment.recognition_image}/>
                            </ShoutoutImage> : null
                          }
                          <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                        </span> :
                        <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                      } */}
                      
                      <div>
                        <StyledLink noFloat={noFloat}>
                          <Img disable={this.state.clicked} onClick={() => this.likeUnlikePost(comment.id, comment.own_like_post)} src={ comment.own_like_post ? "/images/CommentReplyImages/like2.png" : "/images/CommentReplyImages/unlike.png" } />
                          {comment.comment_like_count !== 0 && <div>{comment.comment_like_count}</div>}
                          <div onClick={() => this.showTextField(comment.id)}>| {t("Reply")} {chunkOfReply.length > 0 ? `. ${chunkOfReply.length}` : ''}</div>
                        </StyledLink>
                        <TimeContent fullWidth={replyTextField === comment.id}>{t(TimeOfComment)}</TimeContent>
                      </div>
                    </CommentSection>
                    {replyTextField === comment.id && this.renderTextArea(replyText, comment.id, comment.fullname )}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMMM DD');
                        return(
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <ImageWrapper comment={1}>
                              {this.showProfileImage(reply, false)}
                            </ImageWrapper>
                            <CommentSection isReply noFloat={noFloat}>
                              <div className={"profileName"}>
                                {reply.fullname}
                              </div>
                              {!this.props.isChallengeOrEvent ? <RecognitionText  padding={'0px'}>
                                {!_.isNull(reply.core_value) && <span className="first-span">
                                  {t("Giving")}
                                  <Img src={`${ImageUrl}/${reply.recognition_image}`} />
                                  {reply.core_value} {t("to")}
                                </span>}
                                {!_.isNull(reply.shoutout_user_name) && <span className="second-span">
                                  {
                                    !_.isNull(reply.shoutout_user_name) ? 
                                      // <span dangerouslySetInnerHTML={{__html: convertUnicode(reply.shoutout_user_name).split('-')[0]}}/> : null
                                      <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name).split('-')[0]))}</span> : null
                                  }
                                </span>}
                                {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} /> */}
                                <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                              </RecognitionText> : /* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} /> */ <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>}
                              {/* <span>{reply.uid === userId ? 'You' : reply.fullname}&nbsp;</span> */}
                              {/*<div>.</div>*/}
                              {/* { !this.props.isChallengeOrEvent ?
                                <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                                  { !_.isNull(reply.shoutout_user_name) ? 
                                    <span dangerouslySetInnerHTML={{__html: convertUnicode(reply.shoutout_user_name)}}/> : null
                                  }
                                  { reply.recognition_image != null ? 
                                    <ShoutoutImage comment>
                                      <Image image={reply.recognition_image}/>
                                    </ShoutoutImage> : null
                                  }
                                  <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} />
                                </span> : 
                                <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} />
                              } */}
                              {
                                reply.image ? <div className="image">
                                  <Img src={`${ImageUrl}/${reply.image}`} />
                                </div>: null
                              }
                              <div>
                                <StyledLink noFloat={noFloat} >
                                  <Img disable={this.state.clicked} onClick={() => this.likeUnlikePostCommentReply(reply.id, reply.own_like_post)} src={ reply.own_like_post ? "/images/CommentReplyImages/like2.png" : "/images/CommentReplyImages/unlike.png" } />
                                  {reply.reply_like_count !== 0 && <div>{reply.reply_like_count}</div>}
                                  <div onClick={() => this.showTextField(reply.id)}>| {t("Reply")}</div>
                                </StyledLink>
                                <TimeContent isReply>{t(TimeOfReply)}</TimeContent>
                              </div>
                            </CommentSection>
                            {replyTextField === reply.id && this.renderTextArea(replyText, comment.id, comment.fullname )}
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&
                        <ViewMoreLink isReply onClick={this.props.renderViewCommentModal}>
                          {t("View More Replies")}
                        </ViewMoreLink>
                    }
                  </CommentContent>
                </SingleComment>
              )
            }) : comments.map((comment, index) => {
              const TimeOfComment = moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMMM DD');
              const chunkOfReply = (!_.isEmpty(comment.replies) ?comment && comment.replies && comment.replies.slice(0, replyCount) : []);
              return (
                <SingleComment key={index} noFloat={noFloat}>
                  <CommentContent noFloat={noFloat}>
                    <ImageWrapper comment={1}>
                      {this.showProfileImage(comment, false)}
                    </ImageWrapper>
                    <CommentSection noFloat={noFloat}>
                      <div className={"profileName"}>
                        {t(comment.fullname)}
                      </div>
                      {!this.props.isChallengeOrEvent ? <RecognitionText padding={'0px'}>
                        {/* <span>{comment.uid === userId ? 'You' : comment.fullname}&nbsp;</span> */}
                        {!_.isNull(comment.core_value) && <span className="first-span">
                          {t("Giving")}
                          <Img src={`${ImageUrl}/${comment.recognition_image}`} />
                          {comment.core_value} {t("to")}
                        </span>}
                        {!_.isNull(comment.shoutout_user_name) && <span className="second-span">
                          { !_.isNull(comment.shoutout_user_name) ?
                            // <span dangerouslySetInnerHTML={{__html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0]}}/> : null
                            <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0]))}</span> : null
                          }
                        </span>}
                        {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} /> */}
                        <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      </RecognitionText>:
                        // <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                        <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      }
                      {
                        comment.image ? <div className="image">
                          <Img src={`${ImageUrl}/${comment.image}`} />
                        </div>: null
                      }
                      {/* <div>.</div>
                      { !this.props.isChallengeOrEvent ?
                        <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                          { !_.isNull(comment.shoutout_user_name) ?
                            <span dangerouslySetInnerHTML={{__html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, ""))}}/> : null
                          }
                          { comment.recognition_image != null ? 
                            <ShoutoutImage comment>
                              <Image image={comment.recognition_image}/>
                            </ShoutoutImage> : null
                          }
                          <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                        </span> :
                        <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} />
                      } */}
                      
                      <div>
                        <StyledLink noFloat={noFloat}>
                          <Img disable={this.state.clicked} onClick={() => this.likeUnlikePost(comment.id, comment.own_like_post)} src={ comment.own_like_post ? "/images/CommentReplyImages/like2.png" : "/images/CommentReplyImages/unlike.png" } />
                          {comment.comment_like_count !== 0 && <div>{comment.comment_like_count}</div>}
                          <div onClick={() => this.showTextField(comment.id)}>| {t("Reply")} {chunkOfReply.length > 0 ? `. ${chunkOfReply.length}` : ''}</div>
                        </StyledLink>
                        <TimeContent fullWidth={replyTextField === comment.id}>{t(TimeOfComment)}</TimeContent>
                      </div>
                    </CommentSection>
                    {replyTextField === comment.id && this.renderTextArea(replyText, comment.id, comment.fullname )}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMMM DD');
                        return(
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <ImageWrapper comment={1}>
                              {this.showProfileImage(reply, false)}
                            </ImageWrapper>
                            <CommentSection isReply noFloat={noFloat}>
                              <div className={"profileName"}>
                                {reply.fullname}
                              </div>
                              {!this.props.isChallengeOrEvent ? <RecognitionText  padding={'0px'}>
                                {!_.isNull(reply.core_value) && <span className="first-span">
                                  {t("Giving")}
                                  <Img src={`${ImageUrl}/${reply.recognition_image}`} />
                                  {reply.core_value} {t("to")}
                                </span>}
                                {!_.isNull(reply.shoutout_user_name) && <span className="second-span">
                                  {
                                    !_.isNull(reply.shoutout_user_name) ? 
                                      // <span dangerouslySetInnerHTML={{__html: convertUnicode(reply.shoutout_user_name).split('-')[0]}}/> : null
                                      <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name).split('-')[0]))}</span> : null
                                  }
                                </span>}
                                {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} /> */}
                                <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                              </RecognitionText> : /* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} /> */ <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>}
                              {/* <span>{reply.uid === userId ? 'You' : reply.fullname}&nbsp;</span> */}
                              {/*<div>.</div>*/}
                              {/* { !this.props.isChallengeOrEvent ?
                                <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                                  { !_.isNull(reply.shoutout_user_name) ? 
                                    <span dangerouslySetInnerHTML={{__html: convertUnicode(reply.shoutout_user_name)}}/> : null
                                  }
                                  { reply.recognition_image != null ? 
                                    <ShoutoutImage comment>
                                      <Image image={reply.recognition_image}/>
                                    </ShoutoutImage> : null
                                  }
                                  <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} />
                                </span> : 
                                <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} />
                              } */}
                              {
                                reply.image ? <div className="image">
                                  <img src={`${ImageUrl}/${reply.image}`} />
                                </div>: null
                              }
                              <div>
                                <StyledLink noFloat={noFloat} >
                                  <img disable={this.state.clicked} onClick={() => this.likeUnlikePostCommentReply(reply.id, reply.own_like_post)} src={ reply.own_like_post ? "/images/CommentReplyImages/like2.png" : "/images/CommentReplyImages/unlike.png" } />
                                  {reply.reply_like_count !== 0 && <div>{reply.reply_like_count}</div>}
                                  <div onClick={() => this.showTextField(reply.id)}>| {t("Reply")}</div>
                                </StyledLink>
                                <TimeContent isReply>{t(TimeOfReply)}</TimeContent>
                              </div>
                            </CommentSection>
                            {replyTextField === reply.id && this.renderTextArea(replyText, comment.id, comment.fullname )}
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&                      
                        <ViewMoreLink isReply onClick={() => this.showMoreReplies(comment.replies.length)}>
                          {t("View More Replies")}
                        </ViewMoreLink>
                    }
                  </CommentContent>
                </SingleComment>
              )
            })
          }
        </FieldContainer>
      </CommentsContainer>
    )
  }
}

PhotoComments.propTypes = {
  comments: PropTypes.array.isRequired,
  fixHeight: PropTypes.bool,
  postReply: PropTypes.func,
  id: PropTypes.number,
  replySuccess: PropTypes.string,
  noFloat: PropTypes.bool,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  history: PropTypes.object,
  userCompany: PropTypes.object,
  isChallengeOrEvent: PropTypes.bool,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  likeUnlikePostComment: PropTypes.func.isRequired,
  disableLikeButton: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  updationList: PropTypes.string,
  likeUnlikeCommentReply: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  showThreeComments: PropTypes.bool,
  renderViewCommentModal: PropTypes.func,
  fromSocialFeed: PropTypes.bool,
  t: PropTypes.func,
  photo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userName: state.profileData.userName,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserName: () => dispatch(getUserName()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history)),
  likeUnlikePostComment: (data, postId) => dispatch(likeUnlikePostComment(data, postId)),
  likeUnlikeCommentReply: (data, postId) => dispatch(likeUnlikeCommentReply(data, postId)),
  disableLikeButton: () => dispatch(disableButton()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PhotoComments));
